<template>
    <div class="disOrderInfo">
        <div class="title">订单信息</div>
        <div class="info">
            <div class="info-item">
                <div class="info-text">订单名称：{{infoData.Name}}</div>
                <div class="info-text">订单类型：{{infoData.Type}}</div>
                <div class="info-text">订单编号：{{infoData.OrderNo}}</div>
                <div class="info-text">外部交易号：{{infoData.TransactionId}}</div>
                <div class="info-text">订单状态：{{infoData.Status}}</div>
                <div class="info-text">下单时间：{{infoData.CreateTime}}</div>
                <div class="info-text">配送方式：{{infoData.DeliveryMode}}</div>
                <div class="info-text">卖家留言：{{infoData.Remark}}</div>
            </div>
            <div class="info-item info-item-active">
                <div class="info-text">订单类型：{{infoData.Type}}</div>
                <div class="info-text">订单编号：{{infoData.OrderNo}}</div>
                <div class="info-text">外部交易号：{{infoData.TransactionId}}</div>
            </div>
            <div class="info-item info-item-active">
                <!--<div class="info-text">支付方式：</div>-->
                <div class="info-text">商品总额:{{infoData.TotalPrice}}</div>
                <div class="info-text">优惠:{{infoData.DiscountMoney}}</div>
                <div class="info-text">价格调整:</div>
                <div class="info-text">运费:{{infoData.FreightFee}}</div>
                <div class="info-text">实收金额：{{infoData.Amount}}</div>

            </div>
        </div>
        <div class="title">分销商信息</div>
        <div class="distributor">
            <div class="distributor-item" v-if="infoData.DistributorList.length>0"
                 v-for="item in infoData.DistributorList">
                <a-avatar :size="35" :src="item.avatarUrl"/>
                <div class="distributor-text">
                    <div>{{item.Name}}</div>
                    <div>{{item.phoneNumber}}</div>
                </div>
                <div class="distributor-text">
                    <div>一级佣金:{{item.ReturningServantMoney}}</div>
                    <div>一级积分：</div>
                </div>
            </div>


        </div>
        <div class="title">订单商品</div>
        <div class="order-title">
            <div class="order-title-item">订单号：{{infoData.OrderNo}}</div>
            <div class="order-title-item">付款时间：{{infoData.PayTime}}</div>
            <div class="order-title-item">微信支付号：{{infoData.TransactionId}}</div>
            <div class="order-title-item">创建订单时间：{{infoData.CreateTime}}</div>
        </div>
        <div class="order-list-title" style="height: 36px;">
            <div style="width: 28%" class="order-list-item">商品</div>
            <div class="order-list-item ">单价</div>
            <div class="order-list-item">数量</div>
            <div class="order-list-item">买家</div>
            <div class="order-list-item">实收</div>
            <div class="order-list-item">订单状态</div>
            <div class="order-list-item">操作</div>
        </div>
        <div class="order-list-title order-list-title-active">
            <div style="width: 28%" class="order-list-item">

                <a-avatar class="avatar-padding" shape="square" :size="64" :src="infoData.OrderImgUrl" />
                <div>订单号： {{infoData.OrderNo}}</div>
            </div>

            <div class="order-list-item">￥{{infoData.AvgPrice}}</div>
            <div class="order-list-item">1</div>
            <div class="order-list-item">
                <a-avatar class="avatar-padding" :size="22" :src="UserInfo.avatarUrl"/>
                <div>
                    <div class="blue-color">{{UserInfo.Name}}</div>
                    <div>{{UserInfo.phoneNumber}}</div>
                </div>
            </div>
            <div class="order-list-item">
                <div>
                    <div>商品总额:{{infoData.TotalPrice}}</div>
                    <div>优惠:{{infoData.DiscountMoney}}</div>
                    <div>运费:{{infoData.FreightFee}}</div>
                    <div style="margin-top: 14px">实收金额：{{infoData.Amount}}</div>
                </div>

            </div>
            <div class="order-list-item">
                <div>
                    <div class="order-status">完成</div>
                    <div class="blue-color">订单详情</div>
                </div>
            </div>
            <div class="order-list-item">
                <div class="blue-color avatar-padding">发货  </div>
                <div class="blue-color avatar-padding">修改地址</div>
                <div class="blue-color">备注</div>
            </div>

        </div>
        <div class="title" style="margin-top: 20px">操作信息</div>
        <div class="action-bth">
            <a-button class="action-bth-item" @click="_action_order(0)" :type="orderStatus===0?'primary':''">订单备注</a-button>
            <a-button class="action-bth-item" @click="_action_order(1)" :type="orderStatus===1?'primary':''">订单日志</a-button>
            <!--<a-button class="action-bth-item" @click="_action_order(2)" :type="orderStatus===2?'primary':''">退款日志{{orderStatus}}</a-button>-->
        </div>
        <a-table v-if="orderStatus===0" :pagination="false" :columns="tableColumns" :data-source="infoData.RemarkList" ></a-table>

        <a-table v-if="orderStatus==1" :pagination="false" :columns="orderColumns" :data-source="infoData.OrderLogList" ></a-table>

        <!--<a-table v-if="orderStatus==2" :pagination="false" :columns="orderColumns" :data-source="infoData.RemarkList" ></a-table>-->
    </div>
</template>

<script>
    export default {
        name: "disOrderInfo",
        data() {
            return {
                id: this.$route.query.id,
                infoData: '',
                orderStatus:0,
                tableColumns: [
                    {
                        title: '备注内容',
                        dataIndex: 'Remark',
                        key: 'Remark',
                    },
                    {
                        title: '操作人',
                        dataIndex: 'CreateUserName',
                        key: 'CreateUserName',
                    },
                    {
                        title: '操作时间',
                        dataIndex: 'CreateTime',
                        key: 'CreateTime',
                    },
                   
                    
                ],
                orderColumns:[
                    {
                        title: '操作类型',
                        dataIndex: 'Type',
                        key: 'Remark',
                    },
                    {
                        title: '操作人',
                        dataIndex: 'CreateUserName',
                        key: 'CreateUserName',
                    },
                    {
                        title: '操作时间',
                        dataIndex: 'CreateTime',
                        key: 'CreateTime',
                    },
                ],
                UserInfo:{},
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            loadData() {
                let self = this;
                this.$message.loading('加载中...', 0);
                this.$axios.get(1247, {
                    Id: self.id,

                }, res => {
                    this.$message.destroy();
                    if (res.data.code == 0) {
                        self.infoData = res.data.data;
                        self.UserInfo = res.data.data.UserInfo;
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            _action_order(i){
                this.orderStatus = i;
            },

        }
    }
</script>

<style scoped lang="less">
    .disOrderInfo {
        background: #ffffff;
        padding: 40px;
    }

    .blue-color {
        color: #2994FF;
        font-size: 12px;
    }

    .avatar-padding {
        margin-right: 8px;
    }

    .title {
        color: #1D1D1D;
        font-size: 12px;
        padding-bottom: 6px;
        border-bottom: 1px solid #E0E1E2;
        position: relative;
    }

    .title::after {
        content: '';
        width: 57px;
        height: 2px;
        background: #2994FF;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .info {
        padding: 35px 20px;
        display: flex;
        .info-item {
            width: 33.3%;
            .info-text {
                color: #1D1D1D;
                font-size: 12px;
                margin-bottom: 10px;
            }
        }
        .info-item-active {
            padding-left: 50px;
            position: relative;

        }
        .info-item-active::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 230px;
            background: #DDDDDD;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .distributor {
        margin-top: 20px;
        padding: 34px 56px;
        display: flex;
        border: 1px solid #E0E0E0;
        margin-bottom: 25px;
        .distributor-item {
            width: 50%;
            display: flex;
            align-items: center;
            .distributor-text {
                color: #1D1D1D;
                font-size: 12px;
                margin-left: 8px;
                margin-right: 48px;

            }
        }
    }

    .order-title {
        display: flex;
        text-align: center;
        margin-bottom: 28px;
        margin-top: 28px;
        .order-title-item {
            width: 25%;
            text-align: left;
        }
    }

    .order-list-title {
        background: #F9F9F9;
        display: flex;
        align-items: center;

        border: 1px solid #E0E0E0;
        .order-list-item {
            display: flex;
            width: 12%;
            padding-left: 13px;
            color: #1D1D1D;
            font-size: 12px;
            text-align: left;
            .order-status{
                color: #ffffff;
                font-size: 12px;
                background: #FB6638;
                border-radius: 2px;
                text-align: center;
            }
        }
    }

    .order-list-title-active {
        padding: 16px;
        background: #ffffff;
        border-top: 0px;
    }
    .action-bth{
        margin-top: 20px;
        margin-bottom: 20px;
        .action-bth-item{
            margin-right: 8px;
            width: 87px;
        }
    }
</style>
